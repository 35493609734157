<template>
<g-overlay :is-relay="isRelay"/>
</template>

<script>
import GOverlay from "../components/GOverlay";
import User from "../services/User";
export default {
  name: "Verify",
  components: {GOverlay},
  data() {
    return {
      isRelay: false,
    }
  },
  mounted() {
    this.isRelay = true;
    const code = this.$route.query.code;

    if(!code) {
      this.isRelay = false;
      this.redirect();
    }

    User.verify({code})
    .then(((response) => {
      this.setSnackBar(response.data.message, 'info');
      this.isRelay = false;
      this.redirect();
    }))
    .catch((errors) => {
      this.setSnackBar('The account is verified, please login.', 'error');
      this.isRelay = false;
      this.redirect();
    })

  },
  methods: {
    setSnackBar(text, type) {
      this.$store.commit('SET_SNACKBAR', {text, type});
    },
    redirect() {
      this.$router.push({name: 'Login', path:'/login'})
    }
  }
}
</script>

<style scoped>

</style>